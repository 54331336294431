
import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

// Vue reactivity
import { defineComponent } from 'vue';

// icons
import { close } from 'ionicons/icons';

// components
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Zoom } from 'swiper';
import { IonicSwiper, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, modalController } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Zoom]);

export default defineComponent({
  name: 'ImageModal',
  props: ["imageLink", "caption"],
  components: { Swiper, SwiperSlide, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, },
  setup() {
    const closeModal = async () => { await modalController.dismiss() };
    const slideOpts = {
      zoom: true,
      allowSlideNext: false,
      allowSlidePrev: false,
    };
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close,

      // variables
      slideOpts,
      
      // methods
      closeModal,
    }
  }
});
